<template>
  <div class="page layout-horizontal">
    <div style="overflow: hidden;"
         class="layout-vertical layout-inflexible"
    >
      <div class="padding-horizontal-large padding layout-inflexible font-align-right"
           @click="clickAddReport">
        <ys-button>+ 发送报告</ys-button>
      </div>
      <div class="layout-flexible">
        <ys-infinite-scroll-view
            style="height: 100%; width: 400px;"
            @loadmore="loadMore"
        >
          <div
              v-for="(report, index) in reportList"
              :key="report.id"
              class="padding-horizontal"
          >
            <div
                v-if="index === 0 || report.procedureState !== reportList[index - 1].procedureState"
                class="padding padding-horizontal-large font-size-small font-color-placeholder"
            >
              <span v-if="report.procedureState != null">{{ ProcedureUtils.getStateName(report.procedureState) }}</span>
              <span v-else>更早</span>
            </div>
            <div
                :class="[
                   'list-item layout-horizontal layout-middle padding-horizontal-large padding-vertical font-size-medium',
                   {
                     'is-active': currentReport && currentReport.id === report.id
                   }
                ]"
                @click="clickReport(report)"
            >
              <span class="fas fa-file-medical"></span>
              <div class="layout-flexible margin-left" style="overflow: hidden;">
                <div class="font-nowrap" style="width: 100%;">{{ report.title }}</div>
                <div class="font-size-small font-color-secondary">{{TimeUtils.format('发送时间：yyyy/MM/dd HH:mm', report.time)}}</div>
              </div>
              <div class="layout-inflexible">
                <ys-dropdown>
                  <ys-button type="text"><span class="fas fa-ellipsis-h"></span></ys-button>
                  <el-dropdown-menu slot="dropdown">

                    <el-dropdown-item class="padding-horizontal-large padding-vertical"
                                      @click.native="clickEditReport(report)"><span
                        class="fas fa-pen-alt"></span> 修改
                    </el-dropdown-item>
                    <el-dropdown-item class="padding-horizontal-large padding-vertical font-color-danger"
                                      @click.native="clickDeleteReport(report)"><span
                        class="far fa-trash-alt"></span> 删除
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </ys-dropdown>
              </div>
            </div>
          </div>
        </ys-infinite-scroll-view>
      </div>
    </div>
    <report-preview-panel
        v-if="currentReport"
        :organization-id="organizationId"
        :report-id="currentReport.id"
        class="layout-flexible"
        style="max-width: 500px;"
    ></report-preview-panel>
    <div class="padding padding-large font-size-extra-large font-color-light-placeholder" v-else>未选择报告</div>
    <delete-dialog
        v-if="deletingReport"
        :visible.sync="deleteDialogVisible"
        title="删除报告"
        message="您确定要删除该健康报告吗？"
        @confirm="confirmDeleteReport"
    ></delete-dialog>
    <report-template-picker-dialog
        :organization-id="organizationId"
        :title="选择报告模板"
        :visible.sync="reportTemplatePickerDialogVisible"
        @pick="handleReportTemplatePicked"
    ></report-template-picker-dialog>
    <report-input-dialog
        v-if="pickedReportTemplate || editingReport"
        :organization-id="organizationId"
        :user-id="userId"
        :template-id="pickedReportTemplate ? pickedReportTemplate.id : null"
        :report-id="editingReport ? editingReport.id : null"
        :visible.sync="reportInputDialogVisible"
        @confirm="handleReportInput"
    ></report-input-dialog>
  </div>
</template>

<script>
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";
import {ProcedureUtils} from "@/assets/javascript/procedure-utils";
import YsPopover from "@/components/wedigets/YsPopover";
import YsButton from "@/components/wedigets/YsButton";
import YsDropdown from "@/components/wedigets/YsDropdown";
import DeleteDialog from "@/components/dialog/DeleteDialog";
import ReportPreviewPanel from "@/pages/report/panel/ReportPanel";
import ReportTemplatePickerDialog from "@/components/dialog/ReportTemplatePickerDialog";
import ReportInputDialog from "@/pages/report/dialog/ReportInputDialog";
import {TimeUtils} from "@/assets/javascript/time-utils";

export default {
  name: "ReportPanel",
  mixins: [httpapi],
  components: {
    ReportInputDialog,
    ReportTemplatePickerDialog,
    DeleteDialog, YsDropdown, YsButton, YsPopover, YsInfiniteScrollView, ReportPreviewPanel
  },
  props: {
    organizationId: Number,
    userId: Number,
  },
  data() {
    return {

      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 常量
       */
      ProcedureUtils: ProcedureUtils,
      TimeUtils: TimeUtils,

      /**
       * 报告列表
       */
      reportList: [],
      pageNum: 1,
      pageSize: 20,
      totalPages: 0,

      /**
       * 当前报告
       */
      currentReport: null,

      /**
       * 删除报告
       */
      deletingReport: null,
      deleteDialogVisible: false,

      /**
       * 发送报告
       */
      reportTemplatePickerDialogVisible: false,
      pickedReportTemplate: null,
      reportInputDialogVisible: false,

      /**
       * 修改报告
       */
      editingReport: null,
    }
  },
  computed: {
    inputParams() {
      const {organizationId, userId} = this;
      return {
        organizationId,
        userId,
      }
    }
  },
  watch: {
    inputParams: {
      handler: function () {
        this.loadingCode++;
        this.reportList = [];
        this.currentReport = null;
        this.pageNum = 1;
        this.totalPages = 0;
        this.loadReportList();
      },
      immediate: true,
    }
  },
  methods: {
    clickReport: function (report) {
      this.currentReport = report;
    },
    clickDeleteReport: function (report) {
      this.deletingReport = report;
      this.deleteDialogVisible = true;
    },
    confirmDeleteReport: function () {
      this.deleteReport(this.deletingReport);
    },
    clickEditReport: function (report) {
      this.editingReport = report;
      this.reportInputDialogVisible = true;
    },
    clickAddReport: function () {
      this.reportTemplatePickerDialogVisible = true;
    },
    handleReportTemplatePicked: function (reportTemplateList) {
      this.pickedReportTemplate = reportTemplateList[0];
      this.reportInputDialogVisible = true;
    },
    handleReportInput: function (report) {
      this.$appendBefore(this.reportList, [report]);
      this.currentReport = report;
      this.editingReport = null;
    },
    isBusy: function () {
      return this.busyLoadingCode === this.loadingCode;
    },
    loadReportList: function (pageNum = 1) {
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;
      this.$reqGet({
        path: `/report/list/${this.organizationId}/${this.userId}/${pageNum}/${this.pageSize}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let reportList = pageNum == 1 ? [] : this.reportList;
            this.$appendAfter(reportList, res.data.list);
            this.pageNum = pageNum;
            this.totalPages = res.data.pages;
            this.reportList = reportList;
          })
          .catch(() => {
            this.$message.error('加载失败');
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          })
    },
    loadMore: function () {
      if (this.pageNum < this.totalPages) {
        this.loadReportList(this.pageNum + 1);
      }
    },
    deleteReport: function (report) {
      if (this.isBusy()) {
        this.$showBusy();
        return;
      }
      this.busyLoadingCode = this.loadingCode;
      this.$reqDelete({
        path: `/report/${report.id}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            let deleteIndex = -1;
            for (let n = this.reportList.length - 1; n >= 0; n--) {
              if (this.reportList[n].id === report.id) {
                deleteIndex = n;
                this.reportList.splice(n, 1);
                break;
              }
            }
            if (this.currentReport && this.currentReport.id === report.id) {
              if (this.reportList.length > 0) {
                if (deleteIndex <= 0) {
                  this.currentReport = this.reportList[0];
                } else {
                  this.currentReport = this.reportList[deleteIndex - 1];
                }
              } else {
                this.currentReport = null;
              }
            }
          })
    }
  }
}
</script>

<style scoped>

.list-item {
  width: unset;
  min-width: unset;
}

.list-item.is-active {
  border: 1px solid #ff5500;
  background-color: #faf4f5;
}
</style>